html{
  font-size: 16px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
    scroll-behavior: smooth;

}
html, body { 
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
html::-webkit-scrollbar {
  display: none;
}
body::-webkit-scrollbar{
  display:none;
}


@font-face {
  font-family: inter;
  src: url(../public/fonts/interFont/Inter-Regular.ttf);
}
@font-face {
  font-family: inter;
  src: url(../public/fonts/interFont/Inter-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: inter;
  src: url(../public/fonts/interFont/Inter-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: inter;
  src: url(../public/fonts/interFont/Inter-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: inter;
  src: url(../public/fonts/interFont/Inter-ExtraBold.ttf);
  font-weight: 800;
}

@font-face {
  font-family: monoMedium;
  src: url(../public/fonts/Overpass_Mono/static/OverpassMono-Medium.ttf);
}

:root {
  --brand-lighter-color: #6550FF;
  --brand-primary-color: #513CFF;
  --brand-darker-color: #3D28EB;
  --brand-primary-12-color: rgba(81, 60, 255, 0.12);
  --brand-primary-16-color: rgba(81, 60, 255, 0.16);
  --brand-primary-8-color: rgba(81, 60, 255, 0.08);
  --brand-primary-4-color: rgba(81, 60, 255, 0.04);
  --brand-gradient:linear-gradient(0deg, rgba(85, 107, 249, 0.16) 0%, rgba(85, 107, 249, 0.16) 100%), #FFF;
  --text-primary: #333333;
  --text-medium: #656565;
  --text-lighter: #979797;
  --text-lightest: #E7E7E7;
  --destructive: #FF826D;
  --destructive-12: rgba(255, 130, 109, 0.24);
  --destructive-24: rgba(255, 130, 109, 0.48);
  --destructive-darker: #EB6E59;
  --veryHigh-color: #FF9900;
  --veryHigh-darker-color:#EB8500;
  --high-color: #F6CE00;
  --high-darker-color:#E2BA00;
  --inRange-color: #27C140;
  --inRange-darker-color: #13AD2C;
  --inRange-tight: #009918;
  --inRange-50-color: rgba(39, 193, 64, 0.5);
  --low-color: #FF6C6C;
  --low-darker-color: #EB5858;
  --veryLow-color: #D60000;
  --veryLow-darker-color: #C20000;
  --veryLow-gradient: linear-gradient(0deg, rgba(172, 26, 1, 0.16) 0%, rgba(172, 26, 1, 0.16) 100%), #FFF;
  --bolus-color: #80CFEB;
  --bolus-darker-color: #6CBBD7;
  --basal-color: #4493C0;
  --basal-darker-color: #307FAC;
  --pink: #B644C0;
  --pink-darker: #A230AC;
  --purple: #631EBB;
  --yellow: #F5AB33;
  --yellow-darker: #C3811E;
  --yellow-12: rgba(245, 171, 51, 0.12);
  --yellow-gradient: linear-gradient(0deg, rgba(245, 171, 51, 0.16) 0%, rgba(245, 171, 51, 0.16) 100%), #FFF;
  --green-darker: #1CA080;
  --green: #53D7B7;
  --lime-green: #90C600;
  --green-gradient: linear-gradient(0deg, rgba(28, 160, 128, 0.16) 0%, rgba(28, 160, 128, 0.16) 100%), #FFF;
  --blue: #2CB0F5;
  --blue-darker: #0E92DD;
  --blue-gradient: linear-gradient(0deg, rgba(14, 146, 221, 0.16) 0%, rgba(14, 146, 221, 0.16) 100%), #FFF;
  --blue-12: rgba(44, 176, 245, 0.24);
  --blue-24: rgba(44, 176, 245, 0.48);
  --white-color: #FFFFFF;
  --element-white-40: rgba(255,255,255,0.4);
  --transparentWhite80-color: rgba(255,255,255,0.8);
  --transparentWhite20-color: rgba(255,255,255,0.2);
  --element-bg: #FAFAFA;
  --element-bg-transparent-80: #FAFAFACC;
  --element-bg-dark: #F4F4F4;
  --element-bg-darkest:#DFDEDF;
  --element-bg-90: rgba(250, 250, 250, 0.9);
  --element-bg-80: rgba(250, 250, 250, 0.8);
  --element-stroke: #C9C9C9;
  --transparentWhite40-color: rgba(255,255,255,0.4);
  --trends50: #8CBDDE;
  --trends80: #C7DEEE;
  --trends100: #ECECEC;
  --tooltip-shadow: rgba(51, 51, 51, 0.25);
  --modalBackground: rgba(51,51,51,0.6);
  --scroll-bottom-shadow: linear-gradient(0deg, rgba(51,51,51,0.8), rgba(51,51,51,0)), #FFFFFF;
}


#groot {
  height:100vh;
  width:100vw;
  display: flex;
  position:relative;
}

#groot::-webkit-scrollbar{
  display:none;
}
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-datepicker-popper{
  z-index: 2 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.greyBar{
  border: 0.0625rem solid var(--text-lightest);
}

.recharts-surface {
  overflow: visible;
}
